











import { Component, Mixins, Watch } from 'vue-property-decorator';
import { ListingFilter, ListingOrderBy } from 'client-website-ts-library/filters';
import { ListingStatus, ListingCategory, PropertyCategory } from 'client-website-ts-library/types';
import { View } from 'client-website-ts-library/plugins';

import Listings from '../components/Listings.vue';
import ListingSearch from '../components/ListingSearch.vue';

@Component({
  components: {
    Listings,
    ListingSearch,
  },
})
export default class Properties extends Mixins(View) {
  private listingFilter: ListingFilter | null = null;

  mounted() {
    this.updateFilter();
  }

  @Watch('$route.query')
  updateFilter() {
    let propertyCategories: PropertyCategory[] = [];

    if (this.$route.query.ListingCategory) {
      // eslint-disable-next-line radix
      propertyCategories = (this.$route.query.ListingCategory! as string).split(',').map((v) => parseInt(v) as PropertyCategory);
    }

    const filter = new ListingFilter({
      PropertyCategories: propertyCategories,
      Statuses: [
        ListingStatus.Current,
        ListingStatus.UnderContract,
      ],
      Categories: [
        ListingCategory.Commercial,
        ListingCategory.CommercialLand,
      ],
      PageSize: 9,
      OrderByStatements: [
        ListingOrderBy.CreateDateDesc,
      ],
    });

    if (this.$route.query.priceMin) filter.MinPrice = this.$route.query.priceMin as string;
    if (this.$route.query.priceMax) filter.MaxPrice = this.$route.query.priceMax as string;
    if (this.$route.query.suburbs) filter.Suburbs = (this.$route.query.suburbs as string).split('|');

    this.listingFilter = filter;
  }

  handleFilter(filter: ListingFilter) {
    this.listingFilter = filter;
  }
}
